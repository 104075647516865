<template>
    <div class="comp-select">
        <label><input type="radio" v-model="comp.tutortype" value="ITM" @change="comp.selectType()"><span class="ml-5 mr-20"> ITM</span></label>
        <div class="po-relative dp-inblock w-300px mr-40">
            <input type="text" v-model.trim="comp.itmname" :disabled="comp.tutortype=='CTM'" @keyup="comp.doSearch" class="w-300px" placeholder="강사명 입력 후 선택해주세요.">
            <div v-if="comp.is_selected==false&&comp.tutortype=='ITM'" class="comp-list">
                <div class="comp-item" v-for="(irow, i) in comp.itmList" :key="i" @click="comp.doSelect(irow)">
                    {{ irow.title_s }}<span class="red">{{ irow.title_m }}</span>{{ irow.title_e }}
                </div>
            </div>
        </div>
        <label><input type="radio" v-model="comp.tutortype" value="CTM" @change="comp.selectType()"><span class="ml-5 mr-20"> CTM</span></label>
        <div class="po-relative dp-inblock w-300px">
            <input type="text" v-model.trim="comp.ctmname" :disabled="comp.tutortype=='ITM'" @keyup="comp.doSearch" class="w-300px" placeholder="강사명 입력 후 선택해주세요.">
            <div v-if="comp.is_selected==false&&comp.tutortype=='CTM'" class="comp-list">
                <div class="comp-item" v-for="(irow, i) in comp.ctmList" :key="i" @click="comp.doSelect(irow)">
                    {{ irow.title_s }}<span class="red">{{ irow.title_m }}</span>{{ irow.title_e }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'CarrotTutor',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        tutorname: {
            type: String,
            default: "",
        },
        tutortype: {
            type: String,
            default: "ITM",
        },
    },
    emits: [ 'update:modelValue', 'update:tutorname', 'update:tutortype', 'change' ],
    setup(props, { emit }) {
        const toast = useToast()

        const comp = reactive({
            is_selected : true,
            idx_tutor : 0,
            tutortype : "ITM",
            tutorname : "",
            list : [], total : 0,
            itmList : [],
            ctmList : [],

            doSearchInfo : () => {
                let params = {
                    idx_tutor : comp.idx_tutor,
                    tutortype : comp.tutortype
                };

                if( comp.tutortype == 'CTM' ){
                    // https://stageitm.carrotenglish.net/rest/carrotians/get_tutor_info?idx_tutor=11
                    // https://ctm.carrotenglish.kr/rest_free/carrotfarm/get_tutor_info  
                    let vu = "https://ctm.carrotenglish.kr/rest_free/carrotfarm/get_tutor_info";
                    axios.post(vu, params).then((res) => {
                        if( res.data.err == 0 ){
                            comp.tutorname = res.data.ename + '(' + res.data.kname + ')';
                            console.log(res.data);
                            if( comp.tutortype == 'ITM' ) comp.itmname = comp.tutorname;
                            if( comp.tutortype == 'CTM' ) comp.ctmname = comp.tutorname;
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    });
                } else {
                    // https://itm.carrotenglish.net/rest/carrotians/get_tutor_info
                    let vu = "https://itm.carrotenglish.net/rest/carrotians/get_tutor_info";
                    axios.get(vu, { params : params}).then((res) => {
                        if( res.data.err == 0 ){
                            comp.tutorname = res.data.ename + '(' + res.data.kname + ')';
                            console.log(res.data);
                            if( comp.tutortype == 'ITM' ) comp.itmname = comp.tutorname;
                            if( comp.tutortype == 'CTM' ) comp.ctmname = comp.tutorname;
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    });

                }
                // axios.get("/rest/component/getTutorInfo", { params : params}).then((res) => {
                //     if( res.data.err == 0 ){
                //         comp.tutorname = res.data.ename + '(' + res.data.kname + ')';
                //         console.log(res.data);
                //         if( comp.tutortype == 'ITM' ) comp.itmname = comp.tutorname;
                //         if( comp.tutortype == 'CTM' ) comp.ctmname = comp.tutorname;
                //     } else {
                //         if(res.data.err_msg) toast.error(res.data.err_msg);
                //     }
                // });
            },

            doSearch : () => {
                if( comp.tutortype == 'ITM' ) comp.tutorname = comp.itmname;
                if( comp.tutortype == 'CTM' ) comp.tutorname = comp.ctmname;

                let params = {
                    tutortype : comp.tutortype,
                    tutorname : comp.tutorname
                };

                if( !params.tutorname || params.tutorname.length < 2 ){
                    comp.list  = [];
                    comp.total = 0;
                    comp.is_selected = true;
                    return;
                }

                emit('update:tutortype', comp.tutortype);
                emit('update:tutorname', comp.tutorname);

                if( comp.tutortype == 'CTM' ) {
                    // https://stageitm.carrotenglish.net/rest/carrotians/get_tutor_list?tutorname=test
                    // https://ctm.carrotenglish.kr/rest_free/carrotfarm/get_tutor_list
                    let vu = "https://ctm.carrotenglish.kr/rest_free/carrotfarm/get_tutor_list";
                    axios.post(vu, params).then((res) => {
                        if( res.data.err == 0 ){
                            comp.doMaking(res);
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    });
                } else {
                    // https://itm.carrotenglish.net/rest/carrotians/get_tutor_list?tutorname=test
                    let vu = "https://itm.carrotenglish.net/rest/carrotians/get_tutor_list";
                    axios.get(vu, { params : params}).then((res) => {
                        if( res.data.err == 0 ){
                            comp.doMaking(res);
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    });
                }
            },

            doMaking : (res) => {
                comp.list  = res.data.list;
                comp.total = res.data.total;

                let tutorname = comp.tutorname.toLowerCase();

                for(let i=0; i<comp.list.length; i++){
                    let irow = comp.list[i];

                    let title = irow.ename + ' (' + irow.kname + ')';
                    irow.tutorname = title;

                    title = title.toLowerCase();
                    let s = title.indexOf(tutorname);
                    let l = tutorname.length;

                    s = (s>0?s:0);

                    irow.title_s = irow.tutorname.substr(0, s);
                    irow.title_m = irow.tutorname.substr(s, l);
                    irow.title_e = irow.tutorname.substr(s+l, title.length-(s+l));

                    comp.list[i] = irow;
                }

                if( comp.tutortype == 'ITM' ){
                    comp.itmList = comp.list;
                    comp.ctmList = [];
                }
                if( comp.tutortype == 'CTM' ){
                    comp.itmList = [];
                    comp.ctmList = comp.list;
                }

                if( comp.total > 0 ) comp.is_selected = false;
            },

             selectType : () => {
                emit('update:tutortype',  comp.tutortype);
             },

            doSelect : (o) => {
                // console.log(o);
                comp.is_selected = true;
                comp.idx_tutor = o.idx;
                comp.tutorname = o.tutorname;
                if( comp.tutortype == 'ITM' ) comp.itmname = comp.tutorname;
                if( comp.tutortype == 'CTM' ) comp.ctmname = comp.tutorname;

                emit('update:modelValue', comp.idx_tutor);
                emit('update:tutorname',  comp.tutorname);
                emit('update:tutortype',  comp.tutortype);
                emit('change');
            }
        });

        onMounted(() => {
            // Mounted
            comp.idx_tutor = props.modelValue;
            comp.tutortype = props.tutortype;

            if( comp.idx_tutor > 0 ){
                comp.doSearchInfo();
            }
        });

        return {comp};
   }
}
</script>
<style lang="scss" scoped>
.comp-select {
    position:relative; display: inline-block; height:30px;
    .input-txt {
        width: 100%; height:30px; vertical-align: top;
    }
    .comp-list {
        position: absolute; top:30px; left:0; max-height:200px; overflow:hidden; overflow-y:auto; background-color:#fff; border:1px solid #c8c8c8; padding:5px 8px; z-index: 2;
        .comp-item {
            line-height:30px; cursor: pointer; border-bottom:1px solid #c8c8c8; white-space: nowrap;
            &:hover {
                background-color: #efefef;
            }
            &:last-child {
                border-bottom-width: 0px;
            }
            .red {
                color:#ff854a;
            }
        }
    }
}
</style>