<template>
    <div class="comp-select">
        <input type="text" v-model.trim="comp.bookname" @keyup="comp.doSearch" @keydown="comp.doSearch" class="input-txt" placeholder="교재명 입력 후 선택해주세요.">
        <div v-if="comp.is_selected==false" class="comp-list">
            <div class="comp-item" v-for="(irow, i) in comp.list" :key="i" @click="comp.doSelect(irow)">
                {{ irow.title_s }}<span class="red">{{ irow.title_m }}</span>{{ irow.title_e }}
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'CarrotBookTitle',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        bookname: {
            type: String,
            default: "",
        },
        price: {
            type: Number,
            default: 0,
        },
    },
    emits: [ 'update:modelValue', 'update:bookname', 'update:price' ],
    setup(props, { emit }) {
        const toast = useToast()

        const comp = reactive({
            is_selected : true,
            idx_book : 0,
            bookname : "",
            list : [], total : 0,

            doSearchInfo : () => {
                let params = {
                    idx : comp.idx_book
                };
                axios.get("/rest/component/getBookInfo", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.bookname = res.data.title;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    title : comp.bookname
                };

                if( params.title.length < 2 ){
                    comp.list  = [];
                    comp.total = 0;
                    comp.is_selected = true;
                    return;
                }

                emit('update:bookname', comp.bookname);

                axios.get("/rest/component/getBookList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.list  = res.data.list;
                        comp.total = res.data.total;

                        let bookname = comp.bookname.toLowerCase();

                        for(let i=0; i<comp.list.length; i++){
                            let irow = comp.list[i];

                            let title = irow.title.toLowerCase();
                            let s = title.indexOf(bookname);
                            let l = bookname.length;

                            irow.title_s = irow.title.substr(0, s);
                            irow.title_m = irow.title.substr(s, l);
                            irow.title_e = irow.title.substr(s+l, title.length-(s+l));

                            comp.list[i] = irow;
                        }

                        if( comp.total > 0 ) comp.is_selected = false;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSelect : (o) => {
                comp.idx_book = o.idx;
                comp.bookname = o.title;

                emit('update:modelValue', comp.idx_book);
                emit('update:bookname', comp.bookname);
                emit('update:price', o.price);
                emit('change');

                comp.is_selected = true;
            }
        });

        onMounted(() => {
            // Mounted
            comp.idx_book = props.modelValue;

            if( comp.idx_book > 0 ){
                comp.doSearchInfo();
            }
        });

        return {comp};
   }
}
</script>
<style lang="scss" scoped>
.comp-select {
    position:relative; display: inline-block; height:30px;
    .input-txt {
        width: 100%; height:30px; vertical-align: top;
    }
    .comp-list {
        position: absolute; top:30px; left:0; width:100%; max-height:200px; overflow:hidden; overflow-y:auto; background-color:#fff; border:1px solid #c8c8c8; padding:5px 8px; z-index: 2;
        .comp-item {
            line-height:30px; cursor: pointer; border-bottom:1px solid #c8c8c8; white-space: nowrap;
            &:hover {
                background-color: #efefef;
            }
            &:last-child {
                border-bottom-width: 0px;
            }
            .red {
                color:#ff854a;
            }
        }
    }
}
</style>