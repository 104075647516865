<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="강사용 교재 신청"></CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-row">
                        <colgroup>
                            <col width="180">
                            <col width="*">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>내/외부교재</th>
                                <td>
                                    <label><input type="radio" v-model="add.btype" value="내부"><span class="ml-5 mr-20"> 내부교재</span></label>
                                    <label><input type="radio" v-model="add.btype" value="외부"><span class="ml-5 mr-20"> 외부교재</span></label>
                                </td>
                            </tr>
                            <tr>
                                <th>교재명</th>
                                <td>
                                    <carrot-book-title v-model:bookname.sync="add.bookname" v-model="add.idx_book" class="w-400px"></carrot-book-title>
                                </td>
                            </tr>
                            <tr>
                                <th>소속/강사명</th>
                                <td>
                                    <carrot-tutor v-model:tutortype.sync="add.tutortype" v-model:tutorname.sync="add.tutorname" v-model="add.idx_tutor" class="w-100per"></carrot-tutor>
                                </td>
                            </tr>
                            <tr>
                                <th>클래스</th>
                                <td>
                                    <select :disabled="(add.tutortype=='CTM')==true" v-model="add.idx_class" class="w-400px">
                                        <option value="">선택</option>
                                        <option v-for="(irow, i) in comp.classList" :key="i" :value="irow.idx">{{ irow.classname }}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>출고희망일</th>
                                <td>
                                    <carrot-date-picker v-model="add.request_date" class="w-110px"></carrot-date-picker>
                                    <span class="color-1 ml-10">※ 교재가 필요한 날짜를 선택하세요.</span>
                                </td>
                            </tr>
                            <tr>
                                <th>금액</th>
                                <td>
                                    10,000원
                                    <span class="color-1 ml-10">※ 다음 달 10일 급여에서 공제됩니다.</span>
                                </td>
                            </tr>
                            <tr>
                                <th>비고<br>(세부내용)</th>
                                <td>
                                    <textarea v-model.trim="add.etc_memo" class="w-100per h-100px" maxlength="500"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button @click="add.doCancel" class="btn-default float-left mt-30">취소</button>
                    <button @click="add.doSubmit" class="btn-default float-right mt-30">등록</button>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotBookTitle from "@/components/common/CarrotBookTitle.vue"
import CarrotTutor from "@/components/common/CarrotTutor.vue"


export default {
    layout:"ResourceManagement",
    components: {
        CarrotDatePicker,
        CarrotBookTitle,
        CarrotTutor
    },
    setup() {
        const router = useRouter();
        const toast = useToast();

        const comp = reactive({
            classList : [],

            doSearch : () => {
                if( add.tutortype == "CTM" ) {
                    comp.classList = [];
                    return;
                }
                if( add.idx_tutor <= 0 ){
                    toast.error("강사를 선택하세요.");
                    return;
                }
                let params = {
                    tutortype : add.tutortype,
                    idx_tutor : add.idx_tutor
                };

                if( add.tutortype == "CTM" ) {
                    // https://stageitm.carrotenglish.net/rest/carrotians/get_class_list2?idx_tutor=11
                    axios.get('https://stageitm.carrotenglish.net/rest/carrotians/get_class_list2', { params : params }).then((res) => {
                        if( res.data.err == 0 ){
                            comp.classList = res.data.list;

                            add.idx_class = "";
                            add.classname = "";
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    });
                } else {
                    // https://itm.carrotenglish.net/rest/carrotians/get_class_list2?idx_tutor=11
                    axios.get('https://itm.carrotenglish.net/rest/carrotians/get_class_list2', { params : params }).then((res) => {
                        if( res.data.err == 0 ){
                            comp.classList = res.data.list;

                            add.idx_class = "";
                            add.classname = "";
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    });
                }
            }
        });

        const add = reactive({
            is_loaded  : false,
            utype_list : [],
            info       : {},
            is_show    : true,

            btype             : "내부",
            idx_book          : 0,
            tutortype         : "ITM",
            idx_tutor         : 0,
            tutorname         : "",
            idx_class         : "",
            classname         : "",
            request_date      : "",
            etc_memo          : "",

            doCancel : () => {
                router.go(-1);
                // router.push({ name:"ResourceManagement-TutorTextbookList" });
            },

            doSubmit : () => {
                let params = {
                    btype        : add.btype,
                    idx_book     : add.idx_book,
                    tutortype    : add.tutortype,
                    idx_tutor    : add.idx_tutor,
                    tutorname    : add.tutorname,
                    idx_class    : add.idx_class,
                    request_date : add.request_date,
                    etc_memo     : add.etc_memo,
                };

                if( !params.btype ){
                    toast.error("교재 유형를 선택하세요.");
                    return;
                }
                if( params.idx_book <= 0 ){
                    toast.error("교재를 선택하세요.");
                    return;
                }
                if( !params.idx_tutor ){
                    toast.error("강사를 선택하세요.");
                    return;
                }
                if( params.tutortype == 'ITM' && params.idx_class <= 0 ){
                    toast.error("클래스를 선택하세요.");
                    return;
                }
                for(let i=0; i<comp.classList.length; i++){
                    let irow = comp.classList[i];

                    if( irow.idx == params.idx_class ){
                        params.classname = irow.classname;
                        break;
                    }
                }
                if( !params.request_date ){
                    toast.error("출고희망일을 선택하세요.");
                    return;
                }

                axios.post("/rest/resourceManagement/addTutorBookRental", params).then((res) => {
                    if( res.data.err == 0 ){
                        Swal.fire({
                            title: '강사용 교재 신청',
                            text : '등록하였습니다.'
                        }).then(() => {
                            sessionStorage.setItem('TutorTextbookList', JSON.stringify({
                                page : 1
                            }));
                            router.push({
                                name : 'ResourceManagement-TutorTextbookList'
                            })
                        });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
        });

        watch(() => add.tutortype, (nVal, oVal) => {
            console.log(nVal, oVal);
        });

        watch(() => add.idx_tutor, (nVal, oVal) => {
            if( nVal > 0 && nVal != oVal ){
                comp.doSearch();
            }
        });

        onMounted(() => {
            // Mounted
        });

        return {comp,add};
    }
}
</script>

<style lang="scss" scoped>
</style>